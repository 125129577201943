// src/components/Navbar.js
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
  margin-top: 96px;
`;

const Title = styled.div`
  color: #DA9A4E;
  font-family: "Josefin Sans", Sans-serif;
  font-size: 40px;
  font-weight: bold;
  text-decoration: underline;
`;

const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const GridRow = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
max-width: 840px;
`;

const GridImage = styled.img`
  width: 280px;
  height: 240px;
`;


const Navbar = ({ title }) => {  
  return (
    <Wrapper>
      <Title>{title}</Title>
      <GridWrapper>
        <GridRow>
          <GridImage src="/static/imgs/1.png" />
          <GridImage src="/static/imgs/2.png" />
          <GridImage src="/static/imgs/3.png" />
          <GridImage src="/static/imgs/4.png" />
          <GridImage src="/static/imgs/5.png" />
          <GridImage src="/static/imgs/6.png" />
          <GridImage src="/static/imgs/7.png" />
          <GridImage src="/static/imgs/8.png" />
          <GridImage src="/static/imgs/9.png" />
        </GridRow>
      </GridWrapper>
    </Wrapper>
  );
};

export default Navbar;
