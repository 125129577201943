// src/App.js
import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import MemesGrid from "./components/MemesGrid";
import Tokenomics from "./components/Tokenomics";
import styled from 'styled-components';
import Segue from './components/Segue';
import Footer from "./components/Footer";

const AppWrapper = styled.div`
  background-color: #FBECCF;
  height: 100%;
  width: 100vw;

  h2 {
    color: #fff;
    font-size: 2.5rem;
    font-weight: 700;
    padding: 20px;
    text-align: center;
  }
`;

function App() {
  return (
    <>
      <Navbar />
      <AppWrapper id="homeHeader">
        <Hero />
        <Segue />
        <MemesGrid title="SHYCAT MEMES" set="0" />
        <Footer />
      </AppWrapper>
    </>
  );
}

export default App;
