// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';


const HeaderWrapper = styled.div`
  background-color: #FBECCF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Inner = styled.div`
  background-color: #FBECCF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;

  @media (max-width: 922px) {
    max-width: 1240px;
  }

  @media (max-width: 544px) {
    max-width: 100%;
  }
`;

const LogoImage = styled.img`
  max-width: 100px;
  width: 100px;
  padding: 16px 0;
  border-radius: 50%;

  @media (max-width: 544px) {
    max-width: 80px;
    width: 80px;
  }
`;

const ShyCatText = styled.div`
  color: #da9a4e;
  font-size: 32px;
  font-weight: 600;
  font-family: 'Belanosima', sans-serif;
  line-height: 1.3em;
  font-size: 2rem;
  padding: 0 10px;
`;

const EditorButton = styled.a`
  text-decoration: none;
  font-family: "Josefin Sans", Sans-serif;
  font-size: 32px;
  font-weight: bold;
  fill: #FFFFFF;
  color: #DA9A4E;
  background-color: #00000000;
  border-color: #DA9A4E;
  border-style: solid;
  border-width: 4px 4px 4px 4px;
  border-radius: 32px 32px 32px 32px;
  padding: 20px 40px 12px 40px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  svg {
    margin-top: -1px;
  }
`;

const Navbar = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width state on resize
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Setup window resize event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <HeaderWrapper>
      <Inner>
        <LogoImage src="/static/imgs/logo.png" />
        <EditorButton target="_blank" href="https://editor.shycatsolana.com/">Meme Editor<svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="3" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg></EditorButton>
      </Inner>
    </HeaderWrapper>
  );
};

export default Navbar;
