// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import styled from "styled-components";


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 96px;
  gap: 42px;
`;

const ContractAddress = styled.div`
  color: #DA9A4E;
  font-family: "Josefin Sans", Sans-serif;
  font-size: 32px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;

  @media (max-width: 922px) {
    font-size: 24px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const BuyButton = styled.a`
  text-decoration: none;
  font-family: "Josefin Sans", Sans-serif;
  font-size: 32px;
  font-weight: bold;
  fill: #FFFFFF;
  color: #FFFFFF;
  background-color: #DA9A4E;
  border-style: solid;
  border-width: 4px 4px 4px 4px;
  border-radius: 32px 32px 32px 32px;
  padding: 20px 40px 12px 40px;
  cursor: pointer;
`;

const EditorButton = styled.a`
  text-decoration: none;
  font-family: "Josefin Sans", Sans-serif;
  font-size: 32px;
  font-weight: bold;
  fill: #FFFFFF;
  color: #DA9A4E;
  background-color: #00000000;
  border-color: #DA9A4E;
  border-style: solid;
  border-width: 4px 4px 4px 4px;
  border-radius: 32px 32px 32px 32px;
  padding: 20px 40px 12px 40px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  svg {
    margin-top: -1px;
  }
`;

const Segue = () => {

  const [innerText, setInnerText] = useState('Copy Contract Address');
  const [timer, setTimer] = useState(null);

  const copyToClipboard = () => {
    // Copy text to clipboard
    const textToCopy = '6WErZ2aMZYLUyzbP9n5gm4fwHJbv9Ln8yimSQRwZpump';
    navigator.clipboard.writeText(textToCopy);

    // Change inner text to "Copied!"
    setInnerText('Copied!');

    // Clear any existing timers before setting a new one
    if (timer) {
      clearTimeout(timer);
    }

    // Reset inner text back to the original after 500ms
    const newTimer = setTimeout(() => {
      setInnerText('Copy Contract Address');
    }, 1000);
    setTimer(newTimer);
  };

  // Clean up the timer when the component is unmounted
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);
  
  return (
    <>
      <Wrapper>
        <ContractAddress onClick={copyToClipboard}>{innerText}</ContractAddress>
        <ButtonWrapper>
          <BuyButton target="_blank" href="https://jup.ag/swap/SOL-6WErZ2aMZYLUyzbP9n5gm4fwHJbv9Ln8yimSQRwZpump">BUY SHYCAT</BuyButton>
          <EditorButton target="_blank" href="https://editor.shycatsolana.com/">Make Memes</EditorButton>
        </ButtonWrapper>
      </Wrapper>
    </>
  );
};

export default Segue;
