// src/components/Navbar.js
import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
  text-align: center;
  color: #DA9A4E;
  font-family: "Josefin Sans", Sans-serif;
  padding: 38px 0;
  margin-top: 50px;
  font-size: 24px;
  font-weight: 700;
  width: 100%;
  text-align: center;

  @media (max-width: 544px) {
    font-size: 16px;
  }
`;

const Segue = () => {
  return (
    <>
      <Text>©2024 $SHYCAT, All Right Reserved.</Text>
    </>
  );
};

export default Segue;
