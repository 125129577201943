// src/components/Navbar.js
import React from 'react';
import styled from 'styled-components';

const Title = styled.div``;

const Image = styled.img``;

const Key = styled.div``;

const Value = styled.div``;


const Segue = () => {
  return (
      <>
    </>
  );
};

export default Segue;
